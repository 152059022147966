import {StateTrackedData} from "@common/domain/common.model"


export interface AuthStore {
    status: 'LOGGED_IN' | 'LOGGED_OUT' | 'LOADING'
    user: StateTrackedData<User | undefined>
    token: StateTrackedData<string | undefined>
}

export interface User {
    id: string
    username: string
    email: string
    role: string
    avatar: string
    issuedAt?: number
    expiresAt?: number
    issuer?: string
}

export function buildUserFromToken(token: any): User {
    return {
        id: token.id,
        username: token.sub,
        email: token.email,
        role: token.role,
        avatar: token.avatar,
        issuedAt: token.iat,
        expiresAt: token.exp,
        issuer: token.iss,
    }
}