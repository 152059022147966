import mqtt, {MqttClient, MqttProtocol} from 'mqtt'
import AppConstants from "@src/environment/app.constants";
import {AppDispatch} from "@store";
import {clearMessage, updateMessage} from "@slices/broker.slice";

class BrokerService {
    broker: MqttClient | null;
    dispatch: AppDispatch
    _topics: { [key: string]: Set<string> }

    constructor(dispatch: AppDispatch) {
        this.broker = null;
        this.dispatch = dispatch;
        this._topics = {}
    }

    connect() {
        try {
            if(this.broker){

            this.broker = mqtt.connect(AppConstants.mqtt.url, {
                username: AppConstants.mqtt.username,
                password: AppConstants.mqtt.password,
                protocol: AppConstants.mqtt.protocol as MqttProtocol,
                protocolVersion: 5,
            })

            this.broker.on('connect', () => {
                console.log('Broker connected')
                // console.log("Topicsssss",this._topics)
                // console.log("MANnnnnnnnnnnnnnnnnnnnnn",Object.keys(this._topics).length)
                // if(Object.keys(this._topics).length == 0) return;
                Object.keys(this._topics).forEach((tag) => {
                    console.log("tag...............",tag)
                    this.subscribe(this._topics[tag], tag)
                })
            })

            this.broker.on('disconnect', () => {
                console.log('Broker disconnected')
            })

            this.broker.on('message', (topic, message) => {
                console.log("got a message.....")
                const data = JSON.parse(message.toString())
                this.dispatch(updateMessage({topic, message: data}))
            })
            } else{
                console.log("No broker found to connect...")
            }
        } catch (err){
            console.log("Error",err)
        }
    
    }

    disconnect() {
        if (this.broker) {
            this.broker.end()
        }
    }

    isConnected() {
        return this.broker?.connected ?? false
    }

    subscribe(topic: string | string[] | Set<string>, tag?: string) {
        if (!this.broker || this.broker.disconnected) {
            // throw new Error('Broker not connected')
            return;
        }
        let topics: Set<string> = new Set<string>()

        if (tag) {
            if (!this._topics[tag]) {
                this._topics[tag] = new Set<string>()
            }
            topics = this._topics[tag]

            if (Array.isArray(topic)) {
                topic.forEach((t) => {
                    topics.add(t)
                })
            } else if (topic instanceof Set) {
                topics = topic
            }
            else {
                topics.add(topic)
            }

            this._topics[tag] = topics

        } else {
            // console.log("Array.isArray(topic)",Array.isArray(topic))
            if (Array.isArray(topic)) {
                topic.forEach((t) => {
                    topics.add(t)
                })
            }
            else if (topic instanceof Set) {
                topics = topic
            }
            else {
                topics.add(topic)
            }

            topics.forEach((t) => {
                if(!this._topics[t]) {
                    this._topics[t] = new Set<string>()
                }
                this._topics[t].add(t)
            })
        }

        this.broker.subscribe(Array.from(topics), {qos: 1}, (err, granted) => {
            if (err) {
                console.error(err)
                return
            }
            console.log("Access Granted",granted)
        })
    }

    unsubscribe(topic: string | string[]) {
        if (!this.broker) {
            // throw new Error('Broker not connected')
            return;
        }

        this.broker.unsubscribe(topic, () => {
            this.dispatch(clearMessage({topic}))
        })
    }

    publish(topic: string, message: string) {
        if (!this.broker) {
            // throw new Error('Broker not connected')
            return;
        }

        this.broker.publish(topic, message)
    }

}

export default BrokerService