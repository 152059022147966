import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationResponse, } from "@src/common/domain/common.model";
import { TaggedAction } from "@src/common/domain/common.props";
import api from "@src/common/services/api.service";
import AppConstants from "@src/environment/app.constants";
import { createSecuredSlice } from "@src/utils/auth.utils";
import { ViewerStore } from "@src/views/viewer/domain/viewer.data";
import { NewViewerMinimalRef, ViewerMinimalRef } from "@src/views/viewer/domain/viewer.model";


export const fetchViewerList = createAsyncThunk<ViewerMinimalRef[], TaggedAction>(
    "viewer/list",
    async (_, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/release`;
            const response = await api.get<ViewerMinimalRef[]>(url);
            console.log("response", response);
            return response.data || [];
        } catch (err: any) {
            return thunkAPI.rejectWithValue(err.response?.data || "Unknown error occurred");
        }
    }
);


export const fetchApkInfo = createAsyncThunk<ViewerMinimalRef | undefined, string>("lines/fetchApkInfo",
    async (id) => {

        try {
            const response = await api.get<ApplicationResponse<ViewerMinimalRef>>(`${AppConstants.api}/release?id=${id}`)

            return response.data.data || undefined
        } catch (err) {
            console.log(err)
        }

    })

export const uploadApk = createAsyncThunk<
    any,
    {
        payload: NewViewerMinimalRef;
    }
>("department/upload", async ({payload}, thunkAPI) => {
    try {
        const url = `${AppConstants.api}/release`
        const response = await api.post(url, payload);
        return response.data.data;
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue(err.response.data);
        }

        return thunkAPI.rejectWithValue({
            error: {
                message: err.response.data.message,
                code: err.response.data.code,
            },
        });
    }
});

const viewerSlice = createSecuredSlice({
    name: "viewer",
    initialState: { minimal: { status: "empty", error: null, data: [] }, info: { status: "empty", error: null, data: [] } } as unknown as ViewerStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchViewerList.pending, (state) => {
                state.minimal = {
                    status: "loading",
                    error: null,
                    data: []
                };
            })
            .addCase(fetchViewerList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    error: null,
                    data: action.payload 
                };
            })
            .addCase(fetchViewerList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                };
            });
    },
});

export default viewerSlice.reducer;
