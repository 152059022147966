import AppConstants from "@src/environment/app.constants";
import {ApplicationResponse, TaggedDataState,} from "./../../common/domain/common.model";
import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "@src/common/services/api.service";
import {
    BasicModelReportRef,
    DailyModelReportRef,
    LossSummaryRef,
    ReportStore,
} from "@src/views/dashboard/domain/dashboard.model";
import {createSecuredSlice} from "@utils/auth.utils";
import {format, subDays} from "date-fns";
import {addMissingMonthlyData} from "@utils/report.utils";
import {data} from "autoprefixer"
import { TaggedAction } from "@src/common/domain/common.props";

const Mockdata: any = {
    "Model Pu": [
        {
            id: "1",
            remarks: "Rem-1",
            value: 21
        },
        {
            id: "2",
            remarks: "Rem-2",
            value: 43
        }
    ],
    "Model B": [
        {
            id: "1",
            remarks: "Rem-3",
            value: 54
        },
        {
            id: "2",
            remarks: "Rem-4",
            value: 32
        }
    ],
    "Tg-Minda": [
        {
            id: "1",
            remarks: "Rem-5",
            value: 87
        },
    ]
}


// [
//   {
//     id: '1',
//     model: 'Model A',
//     value: 50,
//     date: '01-05-2024',
//     remarks:""
//   },
//   {
//     id: '2',
//     model: 'Model B',
//     value: 90,
//     date: '02-05-2024',
//     remarks:""
//   },
//   {
//     id: '3',
//     model: 'Model B',
//     value: 90,
//     date: '02-05-2024',
//     remarks:""
//   },
//   // Add more static data as needed
// ];

export const fetchHourlyModelData = createAsyncThunk(
    "dashboard/fetchHourlyModelInfo",
    async ({lineId, modelId}: { modelId?: string; lineId?: string }) => {
        let url = `${AppConstants.api}/entry/report/hourly`;
        if (modelId && lineId) {
            url += `?modelId=${modelId}&lineId=${lineId}`;
        } else if (modelId) {
            url += `?modelId=${modelId}`;
        } else if (lineId) {
            url += `?lineId=${lineId}`;
        }

        const endTime = new Date();
        const startTime = new Date();
        startTime.setHours(endTime.getHours() - 24);

        // Format startTime and endTime to "dd-MM-yyyy HH:mm" format
        const formatDateTime = (dateTime: Date) => {
            const pad = (n: number) => (n < 10 ? "0" + n : n);
            return (
                pad(dateTime.getDate()) +
                "-" +
                pad(dateTime.getMonth() + 1) +
                "-" +
                dateTime.getFullYear() +
                " " +
                pad(dateTime.getHours()) +
                ":" +
                pad(dateTime.getMinutes())
            );
        };

        const params = {
            startTime: formatDateTime(startTime),
            endTime: formatDateTime(endTime), // Fixed typo in endTime
        };

        const response = await api.get<ApplicationResponse<BasicModelReportRef[]>>(
            url,
            {params}
        );
        return response.data.data ?? [];
    }
);

export const fetchDailyModelData = createAsyncThunk(
    "dashboard/fetchDailyModelInfo",
    async ({modelId, lineId}: {modelId?: string, lineId?: string}) => {
        // let url = `${AppConstants.api}/entry/`;
        // if (modelId && modelId !== "ALL") url += `${modelId}/report/daily`;
        // else url += `report/daily`;

        let url = `${AppConstants.api}/entry/`;
        
        if (modelId && modelId !== "ALL") {
            url += `${modelId}/report/daily`;
        } else {
            url += `report/daily`;
        }
        if (lineId) {
            url += `?lineId=${lineId}`;
        }
        const endDate = format(new Date(), "dd-MM-yyyy");
        const startDate = format(subDays(new Date(), 30), "dd-MM-yyyy");

        const params = {
            startDate: startDate,
            endDate: endDate,
        };
        const response = await api.get<ApplicationResponse<BasicModelReportRef[]>>(
            url,
            {
                params,
            }
        );

        return response.data.data ?? [];
        // return Mockdata
    }
);

export const fetchMonthlyModelData = createAsyncThunk(
    "dashboard/fetchMonthlyModelInfo",
    async ({modelId, lineId}: {modelId?: string, lineId?: string}) => {
        // let url = `${AppConstants.api}/entry/`;
        // if (modelId && modelId !== "ALL") url += `${modelId}/report/monthly`;
        // else url += `report/monthly`;
        let url = `${AppConstants.api}/entry/`;
        
        if (modelId && modelId !== "ALL") {
            url += `${modelId}/report/monthly`;
        } else {
            url += `report/monthly`;
        }

        if (lineId) {
            url += `?lineId=${lineId}`;
        }
        const response = await api.get<
            ApplicationResponse<BasicModelReportRef[]>
        >(url);

        const data = response.data.data ?? [];

        return addMissingMonthlyData(data)
    }
);

export const fetchLossSummaryData = createAsyncThunk<TaggedDataState<any>, TaggedAction>(
    "dashboard/fetchLossSummaryInfo",
    async (payload) => {
        const { tag } = payload;
        const url = `${AppConstants.api}/entry/rejection/report/monthly`;
        // if (modelId) url += `?modelId=${modelId}`
        const endDate = format(new Date(), "dd-MM-yyyy");
        const startDate = format(subDays(new Date(), 30), "dd-MM-yyyy");

        const params = {
            startDate: startDate,
            endDate: endDate,
            modelId: tag,
        };
        const response = await api.get<ApplicationResponse<LossSummaryRef[]>>(url, {
            params,
        });

        return {
            tag: payload.tag,
            data: response.data.data ?? []
        }
    }
);

const reportSlice = createSecuredSlice({
    name: "reports",
    initialState: {
        dailyMinimal: {},
        hourlyMinimal: {},
        lossSummary: {minimal : {} },
        monthlyMinimal: {},
    } as ReportStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDailyModelData.pending, (state) => {
                state.dailyMinimal = {
                    status: "loading",
                    error: null,
                    data: [],
                };
            })
            .addCase(fetchDailyModelData.fulfilled, (state, action) => {
                state.dailyMinimal = {
                    status: "idle",
                    error: null,
                    data: action.payload,
                };
            })
            .addCase(fetchDailyModelData.rejected, (state, action) => {
                state.dailyMinimal = {
                    status: "failed",
                    error: action.error.message,
                    data: [],
                };
            })

            .addCase(fetchHourlyModelData.pending, (state) => {
                state.hourlyMinimal = {
                    status: "loading",
                    error: null,
                    data: [],
                };
            })
            .addCase(fetchHourlyModelData.fulfilled, (state, action) => {
                state.hourlyMinimal = {
                    status: "idle",
                    error: null,
                    data: action.payload,
                };
            })
            .addCase(fetchHourlyModelData.rejected, (state, action) => {
                state.hourlyMinimal = {
                    status: "failed",
                    error: action.error.message,
                    data: [],
                };
            })
            .addCase(fetchLossSummaryData.pending, (state, action) => {
                const { arg: { tag } } = action.meta;
                state.lossSummary.minimal[tag] = {
                    status: "loading",
                    error: null,
                    data: [],
                };
            })
            .addCase(fetchLossSummaryData.fulfilled, (state, action) => {
                const { tag, data } = action.payload;
                state.lossSummary.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data,
                };
            })
            .addCase(fetchLossSummaryData.rejected, (state, action) => {
                const { arg: { tag } } = action.meta;
                state.lossSummary.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: [],
                };
            })
            .addCase(fetchMonthlyModelData.pending, (state) => {
                state.monthlyMinimal = {
                    status: "loading",
                    error: null,
                    data: [],
                };
            })
            .addCase(fetchMonthlyModelData.fulfilled, (state, action) => {
                state.monthlyMinimal = {
                    status: "idle",
                    error: null,
                    data: action.payload,
                };
            })
            .addCase(fetchMonthlyModelData.rejected, (state, action) => {
                state.monthlyMinimal = {
                    status: "failed",
                    error: action.error.message,
                    data: [],
                };
            });
    },
});

export default reportSlice.reducer;
