import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import {EditEscalatioDetailRef, EscalationOwnerDetailRef, EscalationStore} from "@views/andon/domain/andon.model"
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchEscalation =
    createAsyncThunk<TaggedDataState<EscalationOwnerDetailRef[]>, TaggedAction>("escalation/fetchEscalation",
        async (payload) => {

            const url = `${AppConstants.api}/escalations/list`

            const response = await api.get<ApplicationResponse<EscalationOwnerDetailRef[]>>(url)
            return {
                tag: payload.tag,
                data: response.data.data || []
            }
        })

export const updateEscalation = createAsyncThunk<void, {
    payload: EditEscalatioDetailRef[]
}>("escalation/edit", async (wrapper, thunkAPI) => {
    try {
        const url = `${AppConstants.api}/escalations/edit`
        const {
            payload,
        } = wrapper

        const response = await api.put<ApplicationResponse<void>>(url, payload)

        return response.data.data
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

        return thunkAPI.rejectWithValue({
            error: {
                message: err.response.data.message,
                code: err.response.data.code
            }
        })
    }
})

const escalationSlice = createSecuredSlice({
    name: "escalation",
    initialState: {} as EscalationStore,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEscalation.pending,
            (state, action: { meta: { arg: TaggedAction } }) => {
                const {arg: {tag}} = action.meta

                state[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchEscalation.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchEscalation.rejected, (state,
                                                action: PayloadAction<unknown, string,
                                                    { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})


export default escalationSlice.reducer