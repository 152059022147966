const AppConstants = {
   // // // Office server env
    // api: 'http://digimach.local:5000',
    // mqtt: {
    //     url: 'ws://digimach.local:61619',
    //     protocol: 'ws',
    //     port: 61619,
    //     host: 'digimach.local',
    //     username: 'mqttuser',
    //     password: 'KZ5UPUNge0fxF65lFwrDdNbV1/6ybI1XsrjUKuN6F8o='
    // },
    // Staging env- qa console
    api: 'https://king-prawn-app-cnjfs.ondigitalocean.app',
    // // api: 'https://api.digimach.io',
    // // api: 'http://localhost:5000',
    mqtt: {
        url: 'wss://broker.qa.api.digimach.io:1883',
        protocol: 'wss',
        port: 1883,
        host: 'wss://broker.qa.api.digimach.io',
        username: 'fapladmin',
        password: 'taKV+RC/d9CJeoMieiMI6VQ4Y9Nnjs3mTZJATBqnsp0='
    },

    // tg-minda staging
    // api: 'http://digimach.local:5000',
    // mqtt: {
    //     url: 'ws://digimach.local:6969',
    //     protocol: 'ws',
    //     port: 6969,
    //     host: 'digimach.local',
    //     username: 'fapladmin',
    //     password: '2/z29O4lbesHwWLr11GYK1XC2LHY7q2k/UV9MOcHyzk='
    // },

    //Exicom Build
    // api: "http://digimach.local:5000",
    // // api:"http://10.34.24.155:5500",
    
    // mqtt: {
    //     url: 'ws://localhost:61619',
    //     protocol: 'ws',
    //     port: 61619,
    //     host: 'localhost',
    //     username: '',
    //     password: ''
    
    // }

    // Lumax build
    // api: 'http://digimach.local:5000',
    // mqtt: {
    //     url: 'ws://digimach.local:1883',
    //     protocol: 'ws',
    //     port: 1883,
    //     host: 'digimach.local',
    //     username: 'mqttuser',
    //     password: 'broker@123'
    // },
     //Havells credentials
     // api: 'http://digimach.local:5500',
     // mqtt: {
     //     url: 'ws://digimach.local:61619',
     //     protocol: 'ws',
     //     port: 61619,
     //     host: 'digimach.local',
     //     username: '',
     //     password: ''
     // },


}

export default AppConstants
