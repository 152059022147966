import {BasicModelReportRef} from "@views/dashboard/domain/dashboard.model";
import moment from "moment-timezone"

const DummyBasicModelRef = {
    id: "",
    productionTarget: 0,
    production: 0,
    oee: 0,
    rejection: 0,
    breakdownLoss: 0,
    availability: 0,
    quality: 0,
    performance: 0,
    date: "",
    modelId: "",
    modelName: "",
    productivity: 0,
    rejectionPercentage: 0,
}

export function addMissingMonthlyData(data: BasicModelReportRef[]) {
    const allMonths = Array.from({length: 12}, (_, i) => moment().subtract(11 - i, "months").format("YYYY-MM"))
    console.log(allMonths)
    // console.log
    // const allMonthsNumber = [1,2,3,4,5,6,7,8,9,10,11,12]
    const dummy =  {...DummyBasicModelRef}
    const newData = data.map(d => {
        return { ...d, date: `${d.year}-${String(d.month).padStart(2, '0')}` }
    });
    console.log("newData...", newData)

    return allMonths.map((month, index) => {
        const monthData = newData.find(d => d.date === month)
        console.log("monthData...", month, monthData)

        // console.log("monthdata",monthData)

        //if date == null
        //d.date =d.year+"-"d.month

        return !monthData ? {
            ...dummy,
            id: (index + 1).toString(),
            date: month,
        } : monthData

    })
}



export function addMissingHourlyData(data: BasicModelReportRef[]) {
    return data
}