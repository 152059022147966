import {createSecuredSlice} from "@utils/auth.utils";

interface BrokerStore {
    [topic: string]: any | null,

    subscribedTopics: any[]
}

const brokerSlice = createSecuredSlice({
    name: 'broker',
    initialState: {
        subscribedTopics: []
    } as BrokerStore,
    reducers: {
        updateMessage(state, action) {
            const {topic, message} = action.payload
            state[topic] = message
        },
        clearMessage(state, action) {
            const {topic} = action.payload
            delete state[topic]
        },
        updateSubscribedTopic(state, action) {
            const {topic} = action.payload
            state.subscribedTopics = state?.subscribedTopics?.length > 0 ? [...state.subscribedTopics, topic] : [topic]
        }
    }
})

export const {updateMessage, clearMessage, updateSubscribedTopic} = brokerSlice.actions

export default brokerSlice.reducer