import api from "@common/services/api.service"
import {createAsyncThunk} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import {ClientConfigurationRef} from "@views/onboarding/domain/onboarding.model"
import {StateTrackedData} from "@common/domain/common.model"
import {createSecuredSlice} from "@utils/auth.utils";


const DEFAULT_LOCALIZATION = {
    line: 'Assembly Line',
    model: 'Model',
    skill: 'Skill'
}


export const fetchConfiguration = createAsyncThunk<ClientConfigurationRef>("configuration/fetchConfiguration",
    async () => {
        const response = await api.get<ClientConfigurationRef>(`${AppConstants.api}/config`)

        // return response.data

        const configResp = response.data

        return {
            ...configResp,
            parameters: {
                ...configResp.parameters,
                localization: {
                    ...DEFAULT_LOCALIZATION,
                    ...(configResp.parameters ?? {}).localization
                }
            }
        }
    })

export const initializeConfiguration = createAsyncThunk<ClientConfigurationRef, string>("configuration/initialize",
    async (payload) => {
        const response = await api.get<ClientConfigurationRef>(`${AppConstants.api}/config/initialize?license=${payload}`)

        return response.data
    })

export const setupConfiguration = createAsyncThunk<any, any>("configuration/setup",
    async (payload) => {
        const response = await api.post<any>(`${AppConstants.api}/config/setup`, payload)

        return response.data
    })

const configurationSlice = createSecuredSlice({
    name: "configuration",
    initialState: {status: "empty"} as StateTrackedData<ClientConfigurationRef | undefined>,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setupConfiguration.pending, (state) => {
                state.status = "loading"
                state.error = undefined
                state.data = undefined
            })
            .addCase(setupConfiguration.fulfilled, (state, action) => {
                state.status = "idle"
                state.error = undefined
                state.data = action.payload
            })
            .addCase(setupConfiguration.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = undefined
            })
            .addCase(initializeConfiguration.pending, (state) => {
                state.status = "loading"
                state.error = undefined
                state.data = undefined
            })
            .addCase(initializeConfiguration.fulfilled, (state, action) => {
                state.status = "idle"
                state.error = undefined
                state.data = action.payload
            })
            .addCase(initializeConfiguration.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = undefined
            })
            .addCase(fetchConfiguration.pending, (state) => {
                state.status = "loading"
                state.error = undefined
                state.data = undefined
            })
            .addCase(fetchConfiguration.fulfilled, (state, action) => {
                state.status = "idle"
                state.error = undefined
                state.data = action.payload
            })
            .addCase(fetchConfiguration.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
                state.data = undefined
            })
    }
})

export default configurationSlice.reducer