import {createAsyncThunk, SerializedError} from "@reduxjs/toolkit"
import {ApplicationResponse, TaggedDataState} from "@src/common/domain/common.model"
import {TaggedAction} from "@src/common/domain/common.props"
import AppConstants from "@src/environment/app.constants"
import api from "@common/services/api.service"
import moment from "moment-timezone"
import {CategoryMinimalRef, CategoryStore, NewCategoryRef} from "@views/andon/domain/andon.model"
import {convertFormat} from "@src/utils/dates"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchCategoryList =
    createAsyncThunk<TaggedDataState<any>, TaggedAction>("category/list",
        async (payload) => {
            const url = `${AppConstants.api}/category/list`
            const response = await api.get<ApplicationResponse<CategoryMinimalRef[]>>(url)

            const data = response.data.data?.map((record) => {
                const processed = Object.assign({}, record)
                const utcTimestamp = moment.tz(record.createdOn.time, convertFormat(record.createdOn.format), record.createdOn.timezone)
                processed.createdOnTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a");
                processed.departmentNames = processed.departments.map((department: any) => department.name)
                return processed

            }) || []
            return {
                tag: payload.tag,
                data: data
            }
        })

export const uploadCategory = createAsyncThunk<void, {
    payload: NewCategoryRef
}>("category/upload",
    async ({payload}, thunkAPI) => {
        try {

            const url = `${AppConstants.api}/category/${payload.id ? 'update' : 'new'}`

            const apiPayload = {
                id: payload.id,
                name: payload.categoryName,
                categoryType: payload.categoryType,
                description: payload.description,
                station: payload.stations,
                departments: payload.departments
            }
            if (payload.id) {
                const response = await api.put<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, apiPayload)
                return response.data.data
            }
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

export const deleteCategory = createAsyncThunk<void, {
    id: string
},
    { rejectValue: SerializedError }
>("category/deleteCategory", async (payload, thunkAPI) => {
    try {
        const {id} = payload;

        const response = await api.delete(`${AppConstants.api}/category/${id}`);

        return response.data;
    } catch (err: any) {
        if (!err.response) {
            return thunkAPI.rejectWithValue({
                name: "NetworkError",
                message: "Network error",
                stack: err.stack,
            });
        }

        return thunkAPI.rejectWithValue({
            name: "ApiError",
            message: "Error from API",
            stack: err.stack,
        });
    }
});


const categorySlice = createSecuredSlice({
    name: "category",
    initialState: {minimal: {}} as CategoryStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoryList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchCategoryList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.minimal[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }
                })
            .addCase(fetchCategoryList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})

export default categorySlice.reducer