import { ApplicationResponse, TaggedDataState } from "@common/domain/common.model"
import { TaggedAction } from "@common/domain/common.props"
import api from "@common/services/api.service"
import { createAsyncThunk, SerializedError } from "@reduxjs/toolkit"
import { fetchLinesInfo, fetchLinesList } from "@slices/lines.slice"
import { ModelStore, NewModelRef } from "@views/assembly-lines/domain/lines.model"
import AppConstants from "@src/environment/app.constants"
import { AssignModelRef, ModelMinimalRef, ModelSearchRef, toModelMinimal, UpdateChangeOverSheetRef } from "@views/models/domain/models.model";
import { createSecuredSlice } from "@utils/auth.utils";

export const assignModelToLines = createAsyncThunk<any, AssignModelRef>("models/assign",
    async (payload, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/models/assign`

            const formData = new FormData()
            formData.append("modelId", payload.modelId!)
            payload.lines.forEach(lineId => {
                formData.append("lines", lineId)
            })
            formData.append('file', payload.file!)

            const response = await api.put<ApplicationResponse<void>>(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            return response.data
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })

export const uploadModel = createAsyncThunk<any, NewModelRef>("models/upload",
    async (payload, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/models`


            const apiPayload = {
                id: payload.id,
                name: payload.name,
                cycleTime: payload.cycleTime
            }
            if (payload.id) {
                const response = await api.put<ApplicationResponse<void>>(url, payload)
                return response.data
            } else {
                const response = await api.post<ApplicationResponse<void>>(url, payload)
                return response.data
            }

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })


    export const updateChangeOverSheet = createAsyncThunk<any, UpdateChangeOverSheetRef>("models/update", async (payload, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/models/line/change-over`;
            const formData = new FormData();
            formData.append("modelId", payload.modelId! );
            formData.append("lineId", payload.lineId !);
            formData.append('file', payload.file!)    
            const response = await api.put<ApplicationResponse<void>>(url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    
            return response.data;
        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data);
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            });
        }
    });


   


export const deleteModel = createAsyncThunk<void, {
    modelId: string
    lineId?: string

},
    { rejectValue: SerializedError }>("models/deleteModel",
        async (payload, thunkAPI) => {
            try {
                const { lineId, modelId } = payload


                // const response = await api.delete(`${AppConstants.api}/models/${modelId}?lineId=${lineId}`)

                let url = `${AppConstants.api}/models/${modelId}`
                if (lineId) url += `?lineId=${lineId}`

                const response = await api.delete(url)
                return response.data

            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue({
                        name: "NetworkError",
                        message: "Network error",
                        stack: err.stack,
                    });
                } else if (err.response.data.error.message === "Model Mapping Not Found") {
                    return thunkAPI.rejectWithValue({
                        name: "ModelMappingError",
                        message: "Model Mapping Not Found",
                        stack: err.stack,
                    });
                } else {
                    // handle other types of errors
                }
            }
        }
    )


export const fetchModelsList =
    createAsyncThunk<TaggedDataState<ModelMinimalRef[]>, TaggedAction>("models/fetchModels",
        async (payload) => {

            let url = `${AppConstants.api}/models`
            if (payload.parameters && payload.parameters.config) url += `?config=${payload.parameters.config}`

            const response = await api.get<ApplicationResponse<ModelMinimalRef[]>>(url)

            return {
                tag: payload.tag,
                data: response.data.data ?? []
            }
        })


export const fetchModelsSearch = createAsyncThunk<TaggedDataState<any>, TaggedAction>("models/searchModels",
    async (payload) => {
        let url = `${AppConstants.api}/models/search`
        if (payload.parameters && payload.parameters.lineId) url += `?lineId=${payload.parameters.lineId}`

        const response = await api.get<ApplicationResponse<ModelSearchRef[]>>(url)
        return {
            tag: payload.tag,
            data: response.data.data
        }
    })
const modelSlice = createSecuredSlice({
    name: "models",
    initialState: { minimal: {}, search: {} } as ModelStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinesInfo.fulfilled, (state, { payload }) => {
                if (payload) {
                    state.minimal[payload.id] = {
                        status: "idle",
                        error: null,
                        data: toModelMinimal(payload?.models ?? [])
                    }
                }
            })
            .addCase(fetchModelsList.pending, (state, action) => {
                const { arg: { tag } } = action.meta

                state.minimal[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchModelsList.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
            .addCase(fetchLinesList.rejected,
                (state, action) => {
                    const { arg: { tag } } = action.meta
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
            .addCase(fetchModelsSearch.pending, (state, action) => {
                const { arg: { tag } } = action.meta

                state.search[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchModelsSearch.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.search[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
            .addCase(fetchModelsSearch.rejected,
                (state, action) => {
                    const { arg: { tag } } = action.meta
                    state.search[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})

export default modelSlice.reducer