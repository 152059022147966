import {RootState} from "@store"
import {StateTrackedData} from "@common/domain/common.model"
import {useAppSelector} from "@src/redux/hooks"

export function useStateTrackedSelector<T>(selector: (state: RootState) => StateTrackedData<T>): StateTrackedData<T | undefined> {
    const data = useAppSelector(selector)

    if (data === undefined) {
        return {status: 'empty', error: undefined, data: undefined}
    } else {
        return data
    }
}

export function useStateTrackedArraySelector<T>(selector: (state: RootState) => StateTrackedData<T>): StateTrackedData<T> {
    const data = useAppSelector(selector)

    if (data === undefined) {
        return {status: 'empty', error: undefined, data: [] as T}
    } else {
        return data
    }
}