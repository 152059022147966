import {ApplicationResponse} from "@common/domain/common.model"
import api from "@common/services/api.service"
import {createAsyncThunk} from "@reduxjs/toolkit"
import AppConstants from "@src/environment/app.constants"
import {
    LevelMinimalRef,
    NewSkillRef,
    SkillMinimalRef,
    SkillStore
} from "@src/views/accounts/components/skill-matrix/domain/skill.model"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchSkillList =
    createAsyncThunk<SkillMinimalRef[]>("skill/fetchSkills",
        async () => {
            const response = await api.get<ApplicationResponse<SkillMinimalRef[]>>(`${AppConstants.api}/skill/list`)
            return response.data.data ? response.data.data.sort((sk1, sk2) => sk1.tag.localeCompare(sk2.tag)) : []
        })

export const fetchLevelList = createAsyncThunk<LevelMinimalRef[]>('skill/fetchLevels',
    async () => {
        const response = await api.get<ApplicationResponse<LevelMinimalRef[]>>(`${AppConstants.api}/levels`)
        return response.data.data ? response.data.data.sort((l1, l2) => l1.tag.localeCompare(l2.tag)) : []
    })

export const uploadSkill = createAsyncThunk<any, NewSkillRef>("skill/upload",
    async (payload, thunkAPI) => {
        try {
            const url = `${AppConstants.api}/skill`


            if (payload.id) {
                const response = await api.put<ApplicationResponse<SkillMinimalRef>>(url, payload)
                return response.data.data
            } else {
                const response = await api.post<ApplicationResponse<SkillMinimalRef>>(url, payload)
                return response.data.data
            }

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: "Network error",
                    code: "NETWORK_ERROR",
                    attributes: {}
                },
                status: "error",
                timestamp: new Date().toISOString()
            })
        }
    })


const skillsSlice = createSecuredSlice({
    name: "skills",
    initialState: {minimal: {status: 'empty'}, levels: {status: 'empty'}} as SkillStore,
    reducers: {},
    extraReducers: (builder) => {
        // builder.addCase(logout, () => ({minimal: {status: 'empty'}, levels: {status: 'empty'}}))
        builder
            .addCase(fetchSkillList.pending,
                (state) => {

                    state.minimal = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchSkillList.fulfilled, (state, action) => {
                const data = action.payload
                state.minimal = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchSkillList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
            .addCase(fetchLevelList.pending,
                (state) => {

                    state.levels = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchLevelList.fulfilled, (state, action) => {
                const data = action.payload
                state.levels = {
                    status: "idle",
                    error: null,
                    data
                }

            })
            .addCase(fetchLevelList.rejected, (state, action) => {
                state.levels = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
    }
})

export default skillsSlice.reducer