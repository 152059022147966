import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {ApplicationResponse, TaggedDataState} from "@src/common/domain/common.model";
import {TaggedAction} from "@src/common/domain/common.props";
import api from "@src/common/services/api.service";
import AppConstants from "@src/environment/app.constants";
import {IncidentInfoRef, NewIncidentRef, NewQNRef, PlantInfoMinimalRef} from "@src/views/plant-info/domain/plant-info.model";
import {createSecuredSlice} from "@utils/auth.utils";

    export const fetchPlantInfo =
    createAsyncThunk<TaggedDataState<PlantInfoMinimalRef[]>, TaggedAction>("plantInfo/fetchPlantInfo",
        async (payload) => {
            const url = `${AppConstants.api}/plant/info`

            const response = await api.get<ApplicationResponse<PlantInfoMinimalRef[]>>(url)
            return {
                tag: payload.tag,
                data: response.data.data || []

            };
        }
    )

export const registerIncident = createAsyncThunk<void, {
    payload: NewIncidentRef
}>("Incident/upload",
    async ({payload}, thunkAPI) => {
        try {

            const url = `${AppConstants.api}/incident`

            const response = await api.post<ApplicationResponse<void>>(url, payload)
            return response.data.data

        } catch (err: any) {
            if (!err.response) {
                return thunkAPI.rejectWithValue(err.response.data)
            }

            return thunkAPI.rejectWithValue({
                error: {
                    message: err.response.data.message,
                    code: err.response.data.code
                }
            })
        }
    })

    export const registerQN = createAsyncThunk<void, {
        payload: NewQNRef
    }>("complaint/upload",
        async ({payload}, thunkAPI) => {
            try {
    
                const url = `${AppConstants.api}/complaint`
    
                const response = await api.post<ApplicationResponse<void>>(url, payload)
                return response.data.data
    
            } catch (err: any) {
                if (!err.response) {
                    return thunkAPI.rejectWithValue(err.response.data)
                }
    
                return thunkAPI.rejectWithValue({
                    error: {
                        message: err.response.data.message,
                        code: err.response.data.code
                    }
                })
            }
        })    

const IncidentInfoSlice = createSecuredSlice({
    name: "incident",
    initialState: {minimal: {}} as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlantInfo.rejected, (state,
                                                  action: PayloadAction<unknown, string,
                                                      { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.minimal[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
            .addCase(fetchPlantInfo.pending,
                (state, action: { meta: { arg: TaggedAction } }) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchPlantInfo.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload
                state.minimal[tag] = {
                    status: "idle",
                    error: null,
                    data
                }

            })
    }
})


export default IncidentInfoSlice.reducer

