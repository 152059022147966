import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import {TaggedAction} from "@common/domain/common.props"
import api from "@common/services/api.service"
import {createAsyncThunk} from "@reduxjs/toolkit"
import {LineMinimalRef, LineSearchRef, LinesStore, LineViewRef} from "@views/assembly-lines/domain/lines.model"
import AppConstants from "@src/environment/app.constants"
import {createSecuredSlice} from "@utils/auth.utils";


export const fetchLinesList =
    createAsyncThunk<TaggedDataState<any>, TaggedAction>("lines/fetchLines",
        async (payload) => {

            const response = await api.get<ApplicationResponse<LineMinimalRef[]>>(`${AppConstants.api}/line/list`)

            return {
                tag: payload.tag,
                data: response.data.data
            }
        })

export const fetchLinesInfo = createAsyncThunk<LineViewRef | undefined, string>("lines/fetchLineInfo",
    async (id) => {

        try {
            const response = await api.get<ApplicationResponse<LineViewRef>>(`${AppConstants.api}/line/info?id=${id}`)

            return response.data.data || undefined
        } catch (err) {
            console.log(err)
        }

    })
export const fetchLinesSearch =
    createAsyncThunk<TaggedDataState<any>, TaggedAction>("lines/fetchLinesSearch",
        async (payload) => {

            const response = await api.get<ApplicationResponse<LineSearchRef[]>>(`${AppConstants.api}/line/search`)

            return {
                tag: payload.tag,
                data: response.data.data
            }
        })

const lineSlice = createSecuredSlice({
    name: "assemblyLines",
    initialState: {minimal: {}, search: {}} as LinesStore,
    reducers: {
        resetLineSlice: (state) => {
            return {minimal: {}, search: {}} as LinesStore
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLinesList.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta

                    state.minimal[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchLinesList.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.minimal[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }

                })
            .addCase(fetchLinesList.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.minimal[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
            .addCase(fetchLinesInfo.pending, (state) => {
                state.info = {
                    status: "loading",
                    error: null,
                    data: undefined
                }
            })
            .addCase(fetchLinesInfo.fulfilled, (state, {payload}) => {
                state.info = {
                    status: "idle",
                    error: null,
                    data: payload
                }
            })
            .addCase(fetchLinesInfo.rejected,
                (state, action) => {
                    state.info = {
                        status: "failed",
                        error: action.error.message,
                        data: undefined
                    }
                })
            .addCase(fetchLinesSearch.pending,
                (state, action) => {
                    const {arg: {tag}} = action.meta

                    state.search[tag] = {
                        status: "loading",
                        error: null,
                        data: []
                    }
                })
            .addCase(fetchLinesSearch.fulfilled,
                (state, action) => {
                    const {
                        tag,
                        data
                    } = action.payload
                    state.search[tag] = {
                        status: "idle",
                        error: null,
                        data
                    }

                })
            .addCase(fetchLinesSearch.rejected,
                (state, action) => {
                    const {arg: {tag}} = action.meta
                    state.search[tag] = {
                        status: "failed",
                        error: action.error.message,
                        data: []
                    }
                })
    }
})
export const {resetLineSlice} = lineSlice.actions
export default lineSlice.reducer