import {AuthStore} from "@auth/domain/auth.config"
import {createSlice, CreateSliceOptions, Slice, SliceCaseReducers} from "@reduxjs/toolkit";


export const isAuthenticated = (auth: AuthStore) => auth.status === 'LOGGED_IN'

type SliceState = Record<string, any>;

// This function takes CreateSliceOptions and returns a Slice
export function createSecuredSlice<
    State extends SliceState,
    CaseReducers extends SliceCaseReducers<State>,
    Name extends string = string
>(options: CreateSliceOptions<State, CaseReducers, Name>): Slice<State, CaseReducers, Name> {
    const {initialState, reducers} = options;

    // Ensuring extraReducers can either modify the state or reset it based on 'auth/logout'
    const enhancedExtraReducers = (builder: any) => {
        // If extraReducers is defined and is a function, call it with the builder
        if (options.extraReducers && typeof options.extraReducers === 'function') {
            options.extraReducers(builder);
        } else if (options.extraReducers && typeof options.extraReducers === 'object') {
            // If extraReducers is an object, add each reducer directly
            Object.entries(options.extraReducers).forEach(([actionType, reducer]) => {
                builder.addCase(actionType, reducer);
            });
        }
        // Add the logout case to reset the state
        builder.addCase('auth/logout', () => initialState);
    };

    // Return the enhanced slice with the added logout case
    return createSlice({
        ...options,
        reducers,
        extraReducers: enhancedExtraReducers,
    });
}